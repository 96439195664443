import { styled } from "@mui/material";

type PropType = {
  avatar: string,
  color: string,
  name: string,
  words: string,
  direction?: "left" | "right",
};

const ContainerRight = styled('div')({
  textAlign: "start",
});

const ContainerLeft = styled('div')({
  textAlign: "end",
});

const Avatar = styled('img')({
  borderRadius: "50%",
  borderWidth: "3px",
  borderStyle: "solid",
  width: "64px",
  height: "64px",
});

const ChatBoxDivRight = styled('div')({
  border: "1px solid rgba(255, 255, 255, 0.2)",
  borderRadius: "0px 72px 72px 16px",
  padding: "12px 24px 8px 12px",
  transform: "translateX(32px)",
  marginTop: "12px",
  width: "fit-content",
  maxWidth: "220px",
});

const ChatBoxDivLeft = styled(ChatBoxDivRight)({
  borderRadius: "72px 0px 16px 72px",
  padding: "12px 12px 8px 24px",
  transform: "translateX(-32px)",
});

const Name = styled('p')({
  color: "rgba(255, 255, 255, 0.6)",
  fontFamily: "Mona-Sans",
  fontSize: "12px",
  lineHeight: "12px",
  textAlign: "left",
  textTransform: "uppercase",
  margin: "0 0 8px 0",
});

const WordsRight = styled('p')({
  color: "white",
  fontSize: "20px",
  lineHeight: "20px",
  textAlign: "left",
  margin: 0,
});

const WordsLeft = styled(WordsRight)({
  textAlign: "right",
});

function Chatbox(props: PropType) {
  let chatboxStyle: React.CSSProperties = {
    backgroundColor: props.color,
  };
  let chatbox;
  if (props.direction === "left") {
    return (
      <ContainerLeft>
        <Avatar src={props.avatar} alt="Avatar" style={{borderColor: props.color}} />
        <ChatBoxDivLeft style={chatboxStyle}>
          <Name>{props.name}</Name>
          <WordsLeft>{props.words}</WordsLeft>
        </ChatBoxDivLeft>
      </ContainerLeft>
    );
  } else {
    return (
      <ContainerRight>
        <Avatar src={props.avatar} alt="Avatar" style={{borderColor: props.color}} />
        <ChatBoxDivRight style={chatboxStyle}>
          <Name>{props.name}</Name>
          <WordsRight>{props.words}</WordsRight>
        </ChatBoxDivRight>
      </ContainerRight>
    );
  }
}

export default Chatbox;