import React from "react";
import { RouteObject } from "react-router-dom";
import Homepage from "./homepage";

const routes: RouteObject[] = [
    {
        path: "/",
        element: <Homepage />,
    }
];

export {routes}