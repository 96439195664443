import { Link, styled } from "@mui/material";
import { useInView } from "react-intersection-observer";
import Chatbox from "./chatbox";
import "./homepage.scss";
import TableTacticLogoImage from './image/tableTacticLogo.svg';
import discord from './image/icons/discord.svg';

const FirstPage = styled('div')({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  width: "100%",
  background: "linear-gradient(270.32deg, #6759F5 0.28%, #8153EE 99.73%)",
});

const CompanyName = styled('h3')({
  color: "white",
  fontWeight: 500,
  fontSize: "24px",
  textAlign: "center",
  letterSpacing: "2px",
  padding: 0,
  marginTop: "62px",
  textTransform: "uppercase",
});

const ContactUsButton = styled('a')({
  background: "linear-gradient(90deg, #F8CE52 0%, #F2A348 100%)",
  border: "none",
  borderRadius: "104px",
  cursor: "pointer",
  padding: "16px",
  fontFamily: "Mona Sans",
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "24px",
  color: "#321E83",
  width: "fit-content",
  textDecorationLine: "none",
});

const ContactUsButtonTop = styled(ContactUsButton)({
  position: "absolute",
  top: "50px",
  right: "48px",
});

const Section = styled('div')({
  padding: "100px 0",
});

const Content = styled('div')({
  flexGrow: 1,
  width: "60%",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const Title = styled('h1')({
  color: "white",
  fontSize: "118px",
  lineHeight: "136px",
  textAlign: "center",
  letterSpacing: "4px",
  textTransform: "uppercase",
  margin: 0,
  marginBottom: "64px",
});

const Text = styled('p')({
  fontWeight: 400,
  fontSize: "40px",
  fontFamily: 'Mona Sans',
  lineHeight: "56px",
  textAlign: "center",
  marginBottom: "24px",
  marginTop: 0,
});

const Description = styled(Text)({
  color: "white",
});

const DiceImage = styled('img')({
  width: "259px",
  position: "absolute",
  left: "10%",
  top: "25%",
});

const CardImage = styled('img')({
  width: "259px",
  position: "absolute",
  right: "10%",
  top: "27%",
});

const Header = styled('h2')({
  fontSize: "80px",
  lineHeight: "96px",
  textAlign: "center",
  letterSpacing: "2px",
  textTransform: "uppercase",
  margin: "24px 0",
});

const TableTacticLogo = styled('img')({
  transform: "translateY(15px)",
});

const FullImage = styled('img')({
  display: "block",
  width: "85%",
  height: "auto",
  margin: "64px auto",
  borderRadius: "32px",
});

const PropertyContainer = styled('div')({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0px",
  gap: "56px",
});

const PropertyRow = styled('div')({
  width: "fit-content",
  margin: "0 auto",
  position: "relative",
});

const PropertyText = styled('p')({
  fontWeight: 500,
  fontSize: "56px",
  lineHeight: "88px",
  textAlign: "center",
  margin: 0,
});

const Separator = styled('div')({
  width: "56px",
  height: 0,
  border: "1px solid black",
});

const WhySection = styled(Section)({
  background: "linear-gradient(180deg, #EEEEEE 0%, rgba(247, 247, 247, 0) 100%)",
});

const ReasonList = styled('div')({
  display: "flex",
  flexDirection: "column",
  gap: "56px",
});

const ReasonText = styled(Text)({
  margin: 0,
});

const CoinsImage = styled('img')({
  position: "absolute",
  transform: "translate(50px, -210px)",
});

const ChessPiecesImage = styled('img')({
  position: "absolute",
  right: "50px",
  transform: "translateY(-115px)",
  zIndex: 1,
});

const ContactButtonContainer = styled('div')({
  display: "flex",
  justifyContent: "center",
  marginTop: "72px",
});

const ContactButtonBottom = styled(ContactUsButton)({
  padding: "24px 120px",
  fontSize: "48px",
  lineHeight: "96px",
});

const Footer = styled('div')({
  color: "white",
  display: "flex",
  justifyContent: "space-between",
  margin: "40px",
});

const LogoFooter = styled('div')({
  margin: "auto",
  textTransform: "uppercase",
  width: "33%",
});

const ContactFooter = styled('div')({
  display: "flex",
  justifyContent: "center",
  width: "33%",
});

const IllustrationFooter = styled('div')({
  margin: "auto",
  textAlign: "right",
  width: "33%",
});

function Homepage() {
  const { ref: chatBox1, inView: chatBox1InView } = useInView({
    triggerOnce: true,
    rootMargin: '-100px 0px',
  });
  const { ref: chatBox2, inView: chatBox2InView } = useInView({
    triggerOnce: true,
    rootMargin: '-100px 0px',
  });
  const { ref: chatBox3, inView: chatBox3InView } = useInView({
    triggerOnce: true,
    rootMargin: '-100px 0px',
  });
  const { ref: chatBox4, inView: chatBox4InView } = useInView({
    triggerOnce: true,
    rootMargin: '-100px 0px',
  });

  return (
    <div>
      <FirstPage>
        <CompanyName>mindhusk</CompanyName>
        <ContactUsButtonTop href="mailto:contact@mindhusk.com">Contact Us</ContactUsButtonTop>
        <Content>
          <Title>tabletop rules.</Title>
          <Description>
            For gamers, from gamers, we digitalize <br />
            tabletop games using AI technology, to <br />
            expand our joy and community.
          </Description>
        </Content>
        <DiceImage src={require("./image/dice.png")} />
        <CardImage src={require("./image/cards.png")} />
      </FirstPage>
      <Section>
        <div ref={chatBox1} className={`chatbox-susan ${chatBox1InView ? "appear" : ""}`}>
          <Chatbox avatar={require("./image/avatar/1.png")} color="#49A04A" name="helen" words="Shall we play a game?" />
        </div>
        <Content>
          <Header>How?</Header>
          <Text>
            Introducing <Link href="https://tabletactic.com/" target="_blank"><TableTacticLogo src={TableTacticLogoImage} /></Link>, <br />
            the ultimate tabletop platform for finding, <br />
            playing, and creating your next game.
          </Text>
        </Content>
        <FullImage src={require("./image/game.png")} />
        <PropertyContainer>
          {/* use flex box to put two boxes at each side of the text */}
          <PropertyRow>
            <PropertyText>
              Rule driven, AI powered
            </PropertyText>
            <div ref={chatBox2} className={`chatbox-right ${chatBox2InView ? "appear" : ""}`}>
              <Chatbox avatar={require("./image/avatar/2.png")} color="#1743F3" name="robot" words="Beep Boop Bop" />
            </div>
          </PropertyRow>
          <Separator />
          <PropertyRow>
            <PropertyText>Immersive, Connected, Fun</PropertyText>
            <div ref={chatBox3} className={`chatbox-left ${chatBox3InView ? "appear" : ""}`}>
              <Chatbox avatar={require("./image/avatar/3.png")} color="#E36E30" name="rogers" words="I can do this all day." direction="left" />
            </div>
          </PropertyRow>
          <Separator />
          <PropertyRow>
            <PropertyText>Build fast, Update faster</PropertyText>
            <div ref={chatBox4} className={`chatbox-right ${chatBox4InView ? "appear" : ""}`}>
              <Chatbox avatar={require("./image/avatar/4.png")} color="#49A04A" name="franklin" words="Time is money, friend" />
            </div>
          </PropertyRow>
        </PropertyContainer>
      </Section>
      <WhySection>
        <CoinsImage src={require("./image/coins.png")} />
        <Content>
          <Header>Why?</Header>
          <ReasonList>
            <ReasonText>
              Rulebook learning shouldn't be the burden <br />
              for enjoying tabletop games
            </ReasonText>
            <ReasonText>The passion of creation shall be free from limitation</ReasonText>
            <ReasonText>Tabletop games deserve so much more</ReasonText>
          </ReasonList>
        </Content>
        <ChessPiecesImage src={require("./image/chessPieces.png")} />
      </WhySection>
      <div className="contact-container">
        <div className="contact-content">
          <Title>Get in!</Title>
          <Description>Contact us to be the first to use TableTactic.</Description>
          <ContactButtonContainer>
            <ContactButtonBottom href="mailto:contact@mindhusk.com">Contact Us</ContactButtonBottom>
          </ContactButtonContainer>
        </div>
        <Footer>
          <LogoFooter>©mindhusk llc 2024</LogoFooter>
          <ContactFooter>
            <Link href="https://discord.gg/3zrQXKKkVw" target="_blank" title="discord">
              <img src={discord} alt="discord icon" />
            </Link>
          </ContactFooter>
          <IllustrationFooter>
            Illustration by <a href="https://icons8.com/illustrations/author/zD2oqC8lLBBA">Icons 8</a> from <a href="https://icons8.com/illustrations">Ouch!</a>
          </IllustrationFooter>
        </Footer>
      </div>
    </div>
  );
}

export default Homepage;
